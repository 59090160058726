import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "página-de-recepção",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#p%C3%A1gina-de-recep%C3%A7%C3%A3o",
        "aria-label": "página de recepção permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Página de recepção`}</h1>
    <h2 {...{
      "id": "guias-conceituais",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guias-conceituais",
        "aria-label": "guias conceituais permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Guias Conceituais`}</h2>
    <p>{`Explicações gerais dos conceitos de Lawtex de nível superior. São úteis construir a compreensão de um tópico específico.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/edi"
        }}>{`EDI`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/visual-law"
        }}>{`Visual Law`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/graph"
        }}>{`Looplex Graph`}</a></li>
    </ol>
    <h2 {...{
      "id": "tutoriais",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tutoriais",
        "aria-label": "tutoriais permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tutoriais`}</h2>
    <p>{`Guias práticos com passo a passo para te auxiliar a atingir um objetivo específico. São úteis quando você está tentando completar alguma tarefa.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/primeiros-passos"
        }}>{`Primeiros passos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/templates-list"
        }}>{`Templates de lógica`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/como-criar-um-workflow"
        }}>{`Como criar um workflow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/fundamentos-do-flowable"
        }}>{`Fundamentos do flowable`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/trabalhando-com-timers-no-flowable"
        }}>{`Trabalhando com timers no flowable`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/s3-browser"
        }}>{`S3 Browser`}</a></li>
    </ol>
    <h2 {...{
      "id": "referências-técnicas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#refer%C3%AAncias-t%C3%A9cnicas",
        "aria-label": "referências técnicas permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Referências Técnicas`}</h2>
    <p>{`Descrições técnicas e detalhadas de como Lawtex funciona. São úteis quando você precisa de informações detalhadas sobre algum aspecto específico da linguagem.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lawtex-page"
        }}>{`Codificação com Lawtex`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/operators-page"
        }}>{`Lista de operadores`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tube-list"
        }}>{`Tubes`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lawsty"
        }}>{`Folha de estilos`}</a></li>
    </ol>
    <h2 {...{
      "id": "links-externos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#links-externos",
        "aria-label": "links externos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Links Externos`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/"
        }}><strong parentName="a">{`Looplex Docs`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://looplex.com.br/academy/"
        }}><strong parentName="a">{`Looplex Academy`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.looplex.com/"
        }}>{`Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pt.stackoverflow.com/questions/tagged/lawtex"
        }}>{`Lawtex Stack Overflow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.looplex.com/"
        }}>{`Documentação API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      